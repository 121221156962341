@import 'mixins.scss';
@import 'variables.scss';
@import 'animations.scss';
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

// common=================================================================
a,
p,
li,
span,
button {
    font-family: $text-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-family;

}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style-type: none;
}

ul {
    padding-left: 0;
}

* {
    font-family: $mainFamily;
}

.heading {
    @include Headline;
}

// .project {
//     width: 112%;
//     position: relative;
//     overflow: hidden;
//     background: $blue;
//     margin-left: -6.5%;
//     margin-top: 10%;
//     margin-bottom: 10%;
//     background: rgba(77, 138, 231, 1);
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(5px);
//     -webkit-backdrop-filter: blur(5px);
//     border: 1px solid rgba(77, 138, 231, 0.3);

//     &:after {
//         content: "";
//         position: absolute;
//         top: 3%;
//         right: 1%;
//         width: 80%;
//         height: 93%;
//         background: transparent;
//         border-top: 5px solid #fff;
//         border-bottom: 5px solid #fff;
//         border-right: 5px solid #fff;
//         z-index: -1;
//     }

//     img {
//         width: 50%;
//         height: 100%;
//         object-fit: cover;
//         filter: drop-shadow(2px 2px 5px rgba($color: #000, $alpha: 0.5));
//     }

//     .project-caption {
//         position: absolute;
//         top: 0;
//         right: 0;
//         width: 50%;
//         height: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         text-transform: capitalize;
//         padding-right: 5%;

//         h6 {
//             color: #fff;
//             line-height: 150%;
//             font-size: 3vw;
//             text-align: center;
//         }

//         .button {
//             position: relative;
//             color: #fff;
//             background: transparent;
//             overflow: hidden;
//             border-top: 1px solid rgba(255, 49, 49, 0.5);
//             border-right: 1px solid rgba(0, 255, 255, 0.5);
//             border-bottom: 1px solid rgba(57, 255, 20, 0.5);
//             border-left: 1px solid rgba(255, 255, 113, 0.5);
//             font-family: sans-serif;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-around;
//             align-items: center;
//             width: 35%;
//             margin-top: 5%;
//             box-shadow: $boxShadow;

//             button {
//                 padding: 5% 5%;
//                 border: none;
//                 background: #fff;
//                 color: $green;
//                 font-size: 1.5vw;
//                 font-weight: bold;
//                 width: 100%;
//             }


//             span {
//                 position: absolute;
//                 border-radius: 100vmax;
//             }

//             .top {
//                 top: -5%;
//                 left: 0;
//                 width: 0;
//                 height: 5px;
//                 background: linear-gradient(90deg, transparent 50%, rgba(236, 126, 47, 0.5), rgb(236, 126, 47));

//             }

//             .right {
//                 top: 0;
//                 right: -1%;
//                 width: 5px;
//                 height: 0;
//                 background: linear-gradient(180deg, transparent 30%, rgba(120, 183, 72, 0.5), rgb(120, 183, 72));
//             }

//             .bottom {
//                 right: 0;
//                 bottom: -5%;
//                 height: 5px;
//                 background: linear-gradient(90deg, rgb(236, 126, 47), rgba(236, 126, 47, 0.5), transparent 50%);
//             }


//             .left {
//                 left: -1%;
//                 bottom: 0;
//                 width: 5px;
//                 height: 0;
//                 background: linear-gradient(180deg, rgb(120, 183, 72), rgba(120, 183, 72), transparent 70%);
//             }

//             .top {
//                 animation: animateTop 5s ease-in-out infinite;
//             }

//             .bottom {
//                 animation: animateBottom 5s ease-in-out infinite;
//             }

//             .right {
//                 animation: animateRight 5s ease-in-out infinite;
//             }

//             .left {
//                 animation: animateLeft 5s ease-in-out infinite;
//             }

//             @keyframes animateTop {
//                 25% {
//                     width: 100%;
//                     opacity: 1;
//                 }

//                 30%,
//                 100% {
//                     opacity: 0;
//                 }
//             }

//             @keyframes animateBottom {

//                 0%,
//                 50% {
//                     opacity: 0;
//                     width: 0;
//                 }

//                 75% {
//                     opacity: 1;
//                     width: 100%;
//                 }

//                 76%,
//                 100% {
//                     opacity: 0;
//                 }
//             }

//             @keyframes animateRight {

//                 0%,
//                 25% {
//                     opacity: 0;
//                     height: 0;
//                 }

//                 50% {
//                     opacity: 1;
//                     height: 100%;
//                 }

//                 55%,
//                 100% {
//                     height: 100%;
//                     opacity: 0;
//                 }
//             }

//             @keyframes animateLeft {

//                 0%,
//                 75% {
//                     opacity: 0;
//                     bottom: 0;
//                     height: 0;
//                 }

//                 100% {
//                     opacity: 1;
//                     height: 100%;
//                 }
//             }

//         }
//     }


// }

.modal {
    z-index: 4000 !important;
    margin-top: 4%;

    .modal-content {
        background: rgba(77, 138, 231, 0.8);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(77, 138, 231, 0.3);
    }

    .modal-header {
        h6 {
            color: #fff;
            margin: 0;
            font-weight: bold;
            font-size: 2vw;
            width: 100% !important;
            text-align: center;
        }

        .btn-close {
            opacity: 1;
        }
    }

    form {

        input,
        textarea,
        select {
            width: 100%;
            padding: 2%;
            margin-bottom: 2%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #fff;
            font-size: 1.3vw;
            color: #fff;
            position: relative;

            &::placeholder {
                color: #fff;
            }

            &:focus,
            &:active {
                outline: none;
            }

            optgroup {
                background: $blue;
                border-radius: 5px;
                font-weight: bold;
                position: absolute;
                right: 0;
                top: 0;

                option {
                    background: #fff;
                    color: $blue;

                }
            }

            option {
                background: $blue;
                color: #fff;
            }
        }

        .drop {
            position: relative;

            &:hover {
                .drop-1 {
                    display: block;

                }
            }

            .drop-btn {
                color: #fff !important;
                background: transparent !important;
                box-shadow: none !important;
                text-align: left;
                border: none;
                border-bottom: 1px solid #fff !important;
                border-radius: 0 !important;
                font-size: 1.2vw;
                width: 100%;
                padding-bottom: 2%;
                padding-left: 2%;
            }

            .drop-1 {
                position: absolute;
                top: 90%;
                left: 0;
                width: 100%;
                padding: 1% 5%;
                margin-top: -2%;
                display: none;
                z-index: 200;

                .drop-content {
                    background: #fff;
                    width: 50%;
                    margin-left: -5%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;


                    &:hover {
                        .d-m {
                            display: block;
                            transition: .5s all ease-in-out;
                            color: #000;

                            p {

                                &:hover {
                                    transition: .5s all ease-in-out;

                                    background: $blue;
                                    color: #fff;
                                }
                            }
                        }

                        svg {
                            rotate: -90deg;
                            transition: .5s all ease-in-out;
                            fill: #fff;
                        }

                        background: $blue;
                        color: #fff;
                        transition: .5s all ease-in-out;

                    }

                    svg {
                        width: 10px;
                        height: 10px;
                        fill: $blue;
                        margin-right: 5%;
                        // rotate: -90deg;
                        // position: absolute;
                        // right: 5%;
                        // top: 30%;
                    }

                    h6 {
                        margin: 0;
                        padding: 5%;
                        cursor: pointer;

                    }

                    .d-m {
                        background: #fff;
                        font-size: 1.1vw;
                        display: none;
                        position: absolute;
                        top: 3%;
                        left: 45%;
                        width: 90%;

                        p {
                            padding: 2% 5%;
                            font-size: 1.2vw;
                            cursor: pointer;
                            margin-bottom: 0;
                        }
                    }

                }
            }
        }


        textarea {
            flex-grow: 1;
            height: 20vh;
        }

        .button {
            background: #fff;
            border: none;
            color: $blue;
            width: 100%;
            padding: 2%;
            box-shadow: $boxShadow;
            border-radius: 10px;
            text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.2);
            font-size: 1.4vw;
        }
    }
}

.corp {
    .banner {
        .button {
            top: 70%;
        }
    }
}



/* ====================================================================
    Banner
=====================================================================*/
.banner {
    position: relative;
    margin-top: 8%;

    img {
        width: 100%;
        filter: brightness(70%);

    }

    .banner-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        h6 {
            font-size: 5vw;
            text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.3);
            text-align: center;
            position: relative;
            color: #fff;
            width: 100%;

            &:before {
                width: 5%;
                height: 8px;
                display: block;
                content: "";
                position: absolute;
                top: 98%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
            }

            &:after {
                width: 20%;
                height: 2px;
                display: block;
                content: "";
                position: relative;
                margin-top: 25px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
            }
        }
    }

    .button {
        position: absolute;
        top: 40%;
        border-radius: 40px 0 0 40px;
        right: 0;
        height: 15%;
        color: #fff;
        background: $blue;
        overflow: hidden;
        border-top: 1px solid rgba(255, 49, 49, 0.5);
        border-right: 1px solid rgba(0, 255, 255, 0.5);
        border-bottom: 1px solid rgba(57, 255, 20, 0.5);
        border-left: 1px solid rgba(255, 255, 113, 0.5);
        font-family: sans-serif;
        display: none;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 15%;
        box-shadow: $boxShadow;

        button {
            padding: 5% 5%;
            border: none;
            background: $blue;
            color: #fff;
            font-size: 1.7vw;
            font-weight: bold;
            width: 100%;
        }


        span {
            position: absolute;
            border-radius: 100vmax;
        }

        .top {
            top: 0;
            left: 0;
            width: 0;
            height: 5px;
            // background: linear-gradient(90deg, transparent 50%, rgba(236, 126, 47, 0.5), rgb(236, 126, 47));
            background: linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.5), rgb(255, 255, 255));


        }

        .right {
            top: 0;
            right: 0;
            width: 5px;
            height: 0;
            // background: linear-gradient(180deg, transparent 30%, rgba(120, 183, 72, 0.5), rgb(120, 183, 72));
            background: linear-gradient(180deg, transparent 30%, rgba(255, 255, 255, 0.5), rgb(255, 255, 255));

        }

        .bottom {
            right: 0;
            bottom: 0;
            height: 5px;
            // background: linear-gradient(90deg, rgb(236, 126, 47), rgba(236, 126, 47, 0.5), transparent 50%);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.5), rgb(255, 255, 255), transparent 50%);

        }


        // .left {
        //     left: -1%;
        //     bottom: 0;
        //     width: 5px;
        //     border-radius: 40px;
        //     height: 0;
        //     background: linear-gradient(180deg, rgb(120, 183, 72), rgba(120, 183, 72), transparent 70%);
        // }

        .top {
            animation: animateTop 5s ease-in-out infinite;
        }

        .bottom {
            animation: animateBottom 5s ease-in-out infinite;
        }

        .right {
            animation: animateRight 5s ease-in-out infinite;
        }

        // .left {
        //     animation: animateLeft 5s ease-in-out infinite;
        // }

        @keyframes animateTop {
            25% {
                width: 100%;
                opacity: 1;
            }

            30%,
            100% {
                opacity: 0;
            }
        }

        @keyframes animateBottom {

            0%,
            50% {
                opacity: 0;
                width: 0;
            }

            75% {
                opacity: 1;
                width: 100%;
            }

            76%,
            100% {
                opacity: 0;
            }
        }

        @keyframes animateRight {

            0%,
            25% {
                opacity: 0;
                height: 0;
            }

            50% {
                opacity: 1;
                height: 100%;
            }

            55%,
            100% {
                height: 100%;
                opacity: 0;
            }
        }

        // @keyframes animateLeft {

        //     0%,
        //     75% {
        //         opacity: 0;
        //         bottom: 0;
        //         height: 0;
        //     }

        //     100% {
        //         opacity: 1;
        //         height: 100%;
        //     }
        // }

    }

    // button {
    //     background: #fff;
    //     border: none;
    //     padding: 2% 2%;
    //     width: 20%;
    //     box-shadow: 2px 2px 5px rgba($color: $blue, $alpha: 0.8);
    //     color: $green;
    //     font-size: 1.7vw;
    //     border-radius: 40px 0 0 40px;
    //     position: absolute;
    //     top: 40%;
    //     right: 0;
    //     display: none;

    //     &:hover {
    //         background: rgba($color: #fff, $alpha: 0.6);
    //         // border: 2px solid #fff;
    //         transition: 0.5s all ease;
    //         overflow: hidden;
    //         color: #000;
    //     }

    // }

}

// Home Starts*********************************************************

/* ====================================================================
    Header
=====================================================================*/

.header {
    @include Flex;
    align-items: center;
    width: 100%;
    padding: 1%;
    box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.3);
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background: #fff;

    .serv {
        position: relative;
        display: block;
        margin: 1% 0;
        padding: 1% 2%;
        border-radius: 20px 20px 0 0;
        font-size: 1.1vw;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 800;


        #services {
            display: flex;
            flex-direction: column;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background: #fff;
            width: 200px;
            height: 150px;
            box-shadow: $boxShadow;

            a {
                margin: 2% 0;
                padding: 0;
                border-radius: 0;
                color: #000;
                font-size: 1.1vw;
                padding: 5%;

                &:hover {
                    background: $greenGradient;
                    color: #fff;
                    transform: none;
                }
            }
        }

        &:hover {

            transform: scale(1.1);
            transition: .5s all ease;
            background: $greenGradient;
            color: #fff;

            svg {
                fill: #fff;
                transition: .5s all ease;
            }

            #services {
                display: flex;
                flex-direction: column;
                transform: none;
            }
        }
    }

    .book {
        background: $greenGradient;
        color: #fff;
        margin-left: 5% !important;

    }

    #tra {
        position: relative;

        svg {
            width: 10px;
            height: 10px;
            margin-top: -2%;
            margin-left: 40%;
        }

        #training {
            display: flex;
            flex-direction: column;
            display: none;
            position: absolute;
            top: -10%;
            left: 100%;
            background: #fff;
            width: 200px;
            height: 180px;
            box-shadow: $boxShadow;

            a {
                margin: 2% 0;
                padding: 0;
                border-radius: 0;
                color: #000;
                font-size: 1vw;
                padding: 5%;
                text-transform: capitalize;

                &:hover {
                    background: $greenGradient;
                    color: #fff;
                    transform: none;
                }
            }
        }

        &:hover {
            #training {
                transition: .5s all ease;
                display: flex;
                flex-direction: column;
                transform: none;
            }
        }
    }

    .logo {
        width: 15%;

        img {
            width: 100%;
        }
    }

    .header-links {
        width: 80%;
        @include Flex;
        position: relative;
        margin-left: auto;

        a {
            position: relative;
            margin: 1% 0;
            padding: 1% 2%;
            border-radius: 20px;
            font-size: 1.1vw;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;

            &:hover {
                transform: scale(1.1);
                transition: .5s all ease;
                background: $greenGradient;
                color: #fff;
            }
        }
    }

    .header-menu {
        svg {
            width: 50px;
            height: 50px;
            display: none;

            path {
                fill: $orange;
            }
        }
    }
}

.header-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 30000;
    background: rgba($color: $blue, $alpha: 0.9);
    display: none;


    svg {
        margin: 5%;
        padding: 2%;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        fill: #fff;
        float: right;
        background: rgba($color: #000, $alpha: 0.7)
    }

    .header-mobile-links {
        @include Flex;
        flex-direction: column;
        padding: 5%;
        margin-top: 20%;


        .serv {
            position: relative;
            font-size: 7vw;
            margin-bottom: 5%;
            color: #fff;
            text-shadow: 2px 2px 2px #000;

            #services {
                display: flex;
                flex-direction: column;
                display: none;
                border-left: 2px solid #fff;
                width: 70%;
                padding: 5%;
                margin-left: 5%;

                a {
                    font-size: 6vw;
                    margin-bottom: 5%;
                    color: #fff;
                    text-shadow: 2px 2px 2px #000;
                    position: relative;
                    margin-left: 10%;

                    &::before {
                        content: "";
                        background: #fff;
                        width: 20px;
                        height: 2px;
                        position: absolute;
                        left: -21%;
                        top: 40%;
                        z-index: -1;
                    }
                }
            }

            &:hover {
                #services {
                    display: flex;
                }
            }
        }

        a {
            font-size: 7vw;
            margin-bottom: 5%;
            color: #fff;
            text-shadow: 2px 2px 2px #000;
        }


    }
}

.whatsapp {
    position: fixed;
    bottom: 5%;
    right: -3%;
    z-index: 30000;
    align-items: center;
    justify-content: center;
    width: 15%;
    box-shadow: 2px 2px 5px rgba($color: $green, $alpha: 0.3);
    background: $Ggrad;
    padding: 1%;
    border-radius: 10px;
    display: flex;

    h6 {
        margin-right: 1%;
        width: 70%;
        font-size: 1.5vw;
        margin-bottom: 0;
        color: #fff;
        text-shadow: none;
    }

    svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        margin-right: 20%;
    }
}

/* ====================================================================
    Slider
=====================================================================*/

.slider {
    margin-top: 8%;
    position: relative;



    .legend,
    .thumbs,
    .carousel-status {
        display: none;
    }

    .carousel {
        position: relative;

        img {
            filter: brightness(60%);
            object-fit: cover;
        }

        .carousel-content {
            .carousel-body {
                position: absolute;
                top: 50%;
                left: 30%;
                transform: translate(-50%, -50%);
                width: 60%;

                .carousel-text {
                    width: 100%;
                    color: #fff;
                    text-align: center;
                    padding: 5%;

                    h3 {
                        font-size: 1.6vw;
                        text-transform: capitalize;
                        margin-bottom: 2%;
                        text-shadow: 2px 2px 2px #000;
                    }

                    span {
                        text-transform: uppercase;
                        font-weight: bolder;
                        font-size: 2vw;
                        text-shadow: 2px 2px 2px #000;
                    }

                    p {
                        margin-top: 2%;
                        text-shadow: 2px 2px 2px #000;
                        font-size: 1.4vw;
                    }

                    a {
                        background: transparent;
                        border: 1px solid #fff;
                        border-radius: 10px;
                        text-transform: capitalize;
                        padding: 2% 3%;
                        font-size: 1.5vw;
                        text-shadow: 2px 2px 2px #000;
                        cursor: pointer;
                        display: block;
                        margin-top: 5%;
                        width: 30%;
                        margin-left: auto;
                        margin-right: auto;

                        &:hover {
                            background: $OrangeGradient;
                            border: none;
                            transition: .5s all ease-in-out;
                        }
                    }
                }


            }

        }

    }

    .form {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 30%;
        height: 80%;
        padding: 2%;
        margin-right: 5%;
        background: rgba(77, 138, 231, 0.5);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(77, 138, 231, 0.3);

        h3 {
            color: #fff;
            text-shadow: 2px 2px 2px #000;
            font-size: 2vw;
            text-align: center;
        }

        form {

            input,
            textarea,
            select {
                width: 100%;
                padding: 2%;
                margin-bottom: 2%;
                background: transparent;
                border: none;
                border-bottom: 1px solid #fff;
                font-size: 1.1vw;
                color: #fff;

                &::placeholder {
                    color: #fff;
                }

                &:focus,
                &:active {
                    outline: none;
                }

                optgroup {
                    background: $blue;
                    border-radius: 5px;
                    font-weight: bold;

                    option {
                        background: #fff;
                        color: $blue;
                    }
                }

                option {
                    background: $blue;
                    color: #fff;
                }
            }

            .drop {
                position: relative;

                &:hover {
                    .drop-1 {
                        display: block;
                    }
                }

                .drop-btn {
                    color: #fff !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    text-align: left;
                    border: none;
                    border-bottom: 1px solid #fff !important;
                    border-radius: 0 !important;
                    font-size: 1.1vw;
                    width: 100%;
                }

                .drop-1 {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    padding: 1% 5%;
                    margin-top: -2%;
                    display: none;

                    .drop-content {
                        background: #fff;
                        width: 50%;
                        margin-left: -5%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;


                        &:hover {
                            .d-m {
                                display: block;
                                transition: .5s all ease-in-out;
                            }

                            svg {
                                rotate: -90deg;
                                transition: .5s all ease-in-out;
                            }
                        }

                        svg {
                            width: 10px;
                            height: 10px;
                            fill: $blue;
                            margin-right: 5%;
                            // rotate: -90deg;
                            // position: absolute;
                            // right: 5%;
                            // top: 30%;
                        }

                        h6 {
                            margin: 0;
                            padding: 5%;
                            cursor: pointer;
                        }

                        .d-m {
                            background: #fff;
                            padding: 5%;
                            font-size: 1.1vw;
                            display: none;
                            position: absolute;
                            top: 3%;
                            left: 45%;
                            width: 90%;

                            p {
                                font-size: 1vw;
                                cursor: pointer;
                            }
                        }

                    }
                }
            }

            textarea {
                flex-grow: 1;
                height: 20vh;
            }

            button {
                background: #fff;
                border: none;
                color: $blue;
                width: 100%;
                padding: 2%;
                box-shadow: $boxShadow;
                border-radius: 10px;
                text-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2)
            }
        }
    }
}



/* ====================================================================
    Courses
=====================================================================*/
.courses {
    padding: 0 5% 5% 5%;

    .courses-body {
        // @include Flex;
        padding-top: 5%;

        // .courses-card {
        //     width: calc(100% / 2);
        // }

        .courses-left {
            margin: 0 auto 2% auto;
            width: 95%;

            p {
                font-size: 1.3vw;
                letter-spacing: 1px;
                line-height: 1.8em;
            }
        }

        .courses-right {
            @include Flex;
            // flex-wrap: wrap;

            .courses-right-card {
                width: 33%;
                border-radius: 10px;
                box-shadow: 1px 1px 5px $blue;
                margin: 1%;
                position: relative;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    filter: brightness(50%);
                    border-radius: 10px;
                    object-fit: cover;
                }

                .courses-right-card-caption {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;



                    h3 {
                        text-align: center;
                        text-transform: uppercase;
                        color: #fff;
                        font-weight: bold;
                        font-size: 2.5vw;
                        letter-spacing: 1px;

                    }
                }

            }
        }
    }
}

/* ====================================================================
    Services
=====================================================================*/

.service-page {
    padding: $padding;

    .training-body {

        h4 {
            text-align: center;
            font-weight: bold;
            font-size: 2.5vw;
            margin-top: 5%;
            margin-bottom: 2%;
            text-transform: capitalize
        }

        .training-intro {
            @include Flex;
            align-items: center;

            &:hover {
                .training-img {
                    .img-before {
                        transition: .5s all ease;
                        top: -2%;
                        left: -1.5%;
                        width: 103%;
                        height: 104%;
                    }
                }

            }

            .training-img {
                width: 40%;
                margin-right: 5%;
                position: relative;
                box-shadow: $boxShadow;

                img {
                    width: 100%;
                }

                .img-before {
                    width: 100%;
                    height: 100%;
                    border: 7px solid $blue;
                    position: absolute;
                    top: 5%;
                    left: -5%;
                    z-index: -1;
                }

            }



            .training-intro-caption {
                width: 50%;

                h3 {
                    text-transform: capitalize;
                    font-weight: bold;
                    margin-bottom: 5%;
                }

                p {
                    font-size: 1.15vw;
                    line-height: 25px;
                }
            }
        }

        .training-types {
            @include Flex;

            .corporate-training,
            .individual-training {
                width: calc(100% / 2.1);
                box-shadow: $boxShadow;
                margin: 1%;
                position: relative;

                img {
                    width: 100%;
                    object-fit: contain;
                }

                h6 {
                    font-size: 1.5vw;
                    background: $blue;
                    color: #fff;
                    padding: 5% 0;
                    text-align: center;
                    position: absolute;
                    bottom: 5%;
                    left: 0;
                    right: 0;
                    box-shadow: $boxShadow;

                    &:hover {
                        transition: .3s all ease;
                        font-size: 1.7vw;
                    }

                }
            }
        }

        .outcome {

            .training-intro-caption {

                ul {
                    @include Flex;
                    flex-wrap: wrap;


                    li {
                        list-style-type: none;
                        box-shadow: $boxShadow;
                        width: calc(100% / 3.2);
                        margin: 1%;
                        border: 5px solid #fff;
                        height: 50vh;

                        &:hover {
                            border: 5px solid $blue;
                            transition: .5s all ease;
                        }

                        img {
                            width: 100%;
                        }

                        p {
                            padding: 3% 5%;
                            font-size: 1.2vw;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }


}


/* ====================================================================
    Training
=====================================================================*/
.corporate {
    padding: 0 5% 5% 5%;
    // margin-top: 7%;

    .corporate-body {
        @include Flex;
        flex-wrap: wrap;
        padding-top: 5%;

        .corporate-card {
            width: calc(100% / 3.2);
            margin: 1%;
            box-shadow: $boxShadow;
            background: $blue;

            img {
                width: 100%;
                object-fit: cover;
            }

            h3 {
                font-size: 1.5vw;
                padding: 5%;
                text-align: center;
                color: #fff;
            }


        }

        .last {
            @include Flex;
            width: 100%;
            // background: #fff;

            .corporate-card-img {
                width: calc(100% /3);
                // margin-right: 5%;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                h3 {
                    font-size: 1.5vw;
                    padding: 5%;
                    text-align: center;
                    color: #fff;
                    background: $blue;
                }
            }

            .corporate-card-text {
                width: 70%;
                margin: 1%;


                ul {
                    @include Flex;
                    rotate: 180deg;
                    margin-top: 8%;

                    li {
                        width: calc(100% / 4.1);
                        list-style-type: none;
                        color: #fff;
                        font-size: 1.3vw;
                        margin: 1%;
                        box-shadow: $boxShadow;
                        color: #000;
                        padding: 4%;
                        background: #fff;
                        border: 5px solid #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        rotate: 180deg;

                        &:first-child {
                            height: 40vh;
                            margin-bottom: -70%;
                        }

                        &:nth-child(2) {
                            height: 30vh;
                        }

                        &:nth-child(3) {
                            height: 20vh;
                        }

                        &:last-child {
                            height: 10vh;
                        }

                        &:hover {
                            background: transparent;
                            color: #fff;
                            transition: .3s all ease;
                        }
                    }
                }
            }
        }
    }
}

.train-the-trainer {
    .corporate-body {
        .corporate-card {
            width: calc(100% / 2.1);

            h3 {
                font-size: 2vw;
            }
        }
    }
}

/* ====================================================================
    Coaching
=====================================================================*/
.coaching {
    padding: 0;
    margin: 0;

    .corporate-body {
        padding: 0;

        .corporate-card {
            h3 {
                font-size: 1.3vw;
                text-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
            }
        }
    }
}

.usp {
    position: relative;
    @include Flex;
    align-items: center;
    padding: 10% 7% 10% 7%;

    .usp-stick {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba($color: $blue, $alpha: 0.2);
        z-index: -1;
        border-radius: 5px;
    }

    .usp-card {
        width: calc(100% / 2.1);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {

            .usp-svg {
                background: #fff;
                transition: .5s all ease;
                transform: scale(1.3);
                cursor: pointer;

                svg {
                    fill: $blue;
                }
            }

            .usp-text {
                background: $Bgrad;
                color: #fff;
                transform: scale(1.1);
                transition: .5s all ease;


                p {
                    &:after {
                        background: $blue;
                        z-index: -1;
                    }
                }
            }

        }

        .usp-svg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            box-shadow: $boxShadow;
            position: relative;
            border: 1px solid $blue;
            background: $Bgrad;

            svg {
                width: 40px;
                height: 40px;
                fill: #fff;
                padding: 3%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .usp-text {
            position: absolute;
            height: 150%;
            width: 150%;
            bottom: -200%;
            left: -30%;
            box-shadow: -2px -2px 5px rgba($color: #000, $alpha: 0.3);
            padding: 5%;
            border-radius: 5px;
            background: #fff;
            border: 1px solid rgba($color: #000, $alpha: 0.2);
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 1.4vw;
                text-align: center;
                display: block;
                padding: 5%;
                font-weight: bold;
                margin: 0;

                &:after {
                    content: "";
                    position: absolute;
                    top: -9%;
                    rotate: 45deg;
                    left: 50%;
                    background: #fff;
                    width: 15px;
                    height: 15px;
                    box-shadow: -2px -2px 2px rgba($color: #000, $alpha: 0.2);
                }
            }



        }

        &:nth-child(even) {

            .usp-text {
                position: absolute;
                top: -200%;
                left: -30%;
                box-shadow: $boxShadow;

                p {

                    &:after {
                        box-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.2);
                        top: 90%;
                    }
                }
            }

        }



    }
}

/* ====================================================================
    Video Gallery
=====================================================================*/

.video-gallery {
    padding: 5%;

    .video-gallery-body {
        @include Flex;
        flex-wrap: wrap;
        margin-top: 5%;

        .video-card {
            width: calc(100% / 3.2);
            height: 30vh;
            margin: 1%;
            color: #fff;
            font-family: $text-family;
            position: relative;
            border: 2px solid $blue;
        }
    }

    .video-stream {
        width: 100% !important;
    }
}

/* ====================================================================
    blog
=====================================================================*/
.blogs {
    padding: 5%;
    background: rgba($color: $blue, $alpha: 0.2);

    .blogs-body {
        @include Flex;
        padding-top: 5%;
        flex-wrap: wrap;

        .blogs-body-content {
            position: relative;
            width: calc(100% / 3.3);
            margin: 2% 1%;
            border-radius: 10px;
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));

            img {
                width: 100%;
                filter: brightness(80%);
                object-fit: cover;
            }

            .blogs-caption {
                position: absolute;
                bottom: 0;
                left: 0;
                color: #fff;
                padding: 5%;
                text-transform: capitalize;
                background: rgba($color: #000000, $alpha: 0.7);
                width: 100%;
                height: 40%;

                h3 {
                    font-size: 1.1vw;
                }

                button {
                    background-color: $orange;
                    // border: 1px solid #fff;
                    border-radius: 5px;
                    color: #fff;
                    padding: 3% 5%;
                    width: 40%;
                    font-size: 1.1vw;
                    border: none;
                    position: absolute;
                    bottom: -40%;

                    &:hover {
                        background: #fff;
                        color: #000;
                        font-weight: bold;
                        transition: .5s all ease;
                    }
                }
            }
        }
    }


}

/* ====================================================================
    Achievements
=====================================================================*/
.achievements {
    padding: 5%;
    background: rgba($color: $blue, $alpha: 0.2);

    .achievements-body {
        @include Flex;
        padding-top: 5%;

        .achievements-card {
            text-align: center;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
            margin: 1%;
            width: calc(100% / 3);
            padding: 3%;
            background-color: #fff;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 2px solid $blue;
                width: 90%;
                height: 90%;
                box-shadow: inset 2px 2px 15px rgba($color: $blue, $alpha: 0.5);
            }

            svg {
                width: 40%;
                height: 40%;
                filter: drop-shadow(2px 2px 2px rgba($color: #000, $alpha: 0.3));

                path {
                    fill: $blue;
                }
            }

            p {
                font-size: 2vw;
                font-weight: bold;
                margin-top: 5%;

                span {
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
                }
            }

            h4 {
                font-size: 1.8vw;
            }
        }
    }
}


/* ====================================================================
    Book
=====================================================================*/
.home-book {
    padding: 5%;
    // background: rgba($color: $blue, $alpha: 0.2);

    .book-body {

        display: flex;
        justify-content: center;

        .book {
            @include Flex;
            margin-top: 5%;
            width: calc(100% / 2);

            &:nth-child(2) {
                .book-caption {
                    h4 {
                        margin-bottom: 10%;
                    }
                }
            }

            .book-img {
                width: 40%;
                // margin-right: 5%;

                img {
                    width: 100%;
                    height: 100%;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
                    display: block;
                    margin: auto;
                    object-fit: cover;
                }
            }


            .book-caption {
                width: 55%;
                padding-left: 5%;
                position: relative;

                span {
                    color: $orange;
                    margin-bottom: 10%;
                    display: block;
                }

                h4 {
                    font-size: 1.5vw;
                }

                p {
                    margin-top: 2%;
                    font-size: 1.2vw;
                    display: flex;

                    b {
                        margin-right: 3%;
                    }
                }

                a {
                    background: $orange;
                    color: #fff;
                    padding: 2% 5%;
                    margin-top: 3%;
                    display: block;
                    width: 100%;
                    text-align: center;
                    border-radius: 5px;
                    font-size: 1.2vw;

                }
            }
        }
    }


}

/* ====================================================================
    Recognization
=====================================================================*/
.recognization {
    padding: 5%;
    background: rgba($color: $blue, $alpha: 0.2);

    .cer-body {
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;

        .cer-body-img {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            max-width: 100%;
            margin: auto 1%;
            // width: calc(100% / 3.5);

            #Frames,
            .Frame {
                list-style: none;
                list-style-type: none;
                margin: 0px;
                padding: 0px;
                text-align: center;
            }

            #Frames {
                margin: 2%;
                // width: calc(100%/2.2);
            }

            .Frame {
                display: inline-block;
                padding: 5%;
                border-width: 20px;
                border-style: solid;
                border-color: #000 #353232 #000 #353232;
                background: #f9f9f9;
                box-shadow: inset 0 2px 5px rgba(0, 0, 0, .6), 0 5px 2px rgba(0, 0, 0, .1), 0 10px 20px rgba(0, 0, 0, .8);
                position: relative;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    top: -175px;
                    right: -20%;
                    width: 400px;
                    height: 400px;
                    transform: rotateZ(-40deg);
                    background-image: linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
                }

                img {
                    border-width: 2px;
                    border-style: solid;
                    border-color: #BBBAB4 #C7C7BF #E5E4DF #C7C7BF;
                    box-shadow: 0 -1px 1px rgba(0, 0, 0, .1), 0 1px 1px 1px rgba(255, 255, 255, .7);
                }
            }

        }

        .first {
            width: 30%;
        }

        .second {
            width: 53%;
        }
    }
}

/* ====================================================================
    Footer
=====================================================================*/
footer {
    padding: $padding;
    background: $blue;

    .footer-body {
        @include Flex;
        color: #fff;

        .footer-about,
        .footer-links,
        .footer-contact,
        .footer-map {
            margin: 1%;

        }

        .footer-about {
            width: 30%;

            h2 {
                font-size: 1.5vw;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 7%;
            }

            p {
                font-size: 1vw;
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }

        .footer-links {
            width: 15%;

            h4 {
                font-size: 1.5vw;
                text-transform: capitalize;
                margin-bottom: 10%;
            }

            .footer-links-content {
                display: flex;
                flex-direction: column;

                a {
                    font-size: 1vw;
                    text-transform: capitalize;
                    margin-bottom: 3%;

                    &:hover {
                        color: rgba($color: #fff, $alpha: 0.8);
                        transform: translatex(5px);
                        transition: .5s all ease;
                    }

                    svg {
                        width: 15px;
                        height: 15px;

                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .footer-contact {
            width: 35%;

            h4 {
                font-size: 1.5vw;
                text-transform: capitalize;
                margin-bottom: 8%;

            }

            .footer-contact-body {
                @include Flex;
                flex-direction: column;

                .footer-contact-content {
                    display: flex;
                    align-items: flex-start;

                    svg {
                        width: 15px;
                        height: 15px;
                        fill: #fff;
                        margin-top: .5%;
                    }

                    a {
                        margin-left: 5%;
                        font-size: 1vw;
                        text-transform: capitalize;
                        margin-bottom: 3%;

                    }
                }

            }


        }

        .footer-map {
            width: 30%;

            iframe {
                width: 100%;
                height: 100%
            }
        }
    }

    .footer-follow {
        margin: 2% 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .svg {
            background: #fff;
            border-radius: 50%;
            position: relative;
            width: 40px;
            height: 40px;
            margin-right: 2%;

            &:hover {
                transform: rotate(360deg);
                transition: .5s all ease;
            }

            svg,
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 1%;
                width: 60%;
                height: 60%;

            }


        }
    }


    .footer-copyright {
        @include Flex;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
        color: #fff;
        justify-content: space-between;
        padding: 2% 0;

        p {
            font-size: 1.1vw;
            margin-left: 5%;

            a {
                font-weight: bold;
            }
        }

        .footer-copyright-content {
            width: 30%;
            color: rgba($color: #fff, $alpha: 0.7);

            a {
                font-size: 1vw;

                &:nth-child(1) {
                    margin: 0 5%;
                }
            }
        }
    }
}


// Home Ends********************************************************************************************************

// Pages Starts*****************************************************************************************************

/* ====================================================================
    About
=====================================================================*/
.about-page {
    padding: 0 5%;

    .about-page-body {
        padding-top: 5%;
        @include Flex;
        align-items: center;

        &:last-child {
            flex-direction: row-reverse;
        }

        .about-p-img {
            display: block;
            width: 30%;
            border: 5px solid $blue;
            box-sizing: border-box;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;

            }
        }

        .about-content {
            width: 70%;
            padding-left: 5%;

            h3 {
                text-transform: capitalize;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 5%;
            }

            p {
                font-size: 1.3vw;
            }
        }
    }

    .vision-mission,
    .values {
        padding: 5% 0;
        @include Flex;

        .vision-content,
        .mission-content,
        .values-content {
            width: calc(100% / 3);
            // border: 1px solid rgba($color: #000, $alpha: 0.1);
            padding: 3% 3%;
            border-radius: 20px;
            position: relative;
            background: rgba($color: $blue, $alpha: 1);
            color: #fff;

            &:hover {
                background: $OrangeGradient;
                color: #fff;
                border: none;
                transition: 500ms all ease;

                svg {
                    transform: scale(1.2);
                    transition: 500ms all ease;
                    left: 5%;
                    top: 5%;

                    path {
                        fill: #fff;
                    }
                }

                button {
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }

            svg {
                width: 55px;
                height: 55px;
                position: absolute;
                top: 5%;
                left: 6%;
                padding: 3%;

                path {
                    fill: #fff;
                }

            }

            h3 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 2vw;
                margin-bottom: 5%;
                text-align: center;
                letter-spacing: 1px;
            }


            p {
                font-size: 1.2vw;

                span {
                    margin: 2% 0;
                    transition: .5s all ease;
                }
            }

            button {
                border: none;
                background: transparent;
                border: 1px solid rgba($color: #fff, $alpha: 0.6);
                border-radius: 5px;
                width: 40%;
                // background: rgba($color: $orange, $alpha: 0.4);
                // color: #fff;
                float: right;
                color: #fff;
            }
        }

        .vision-content,
        .values-content {
            margin-right: 1%;
        }
    }
}


/* ====================================================================
    Contact
=====================================================================*/
.contact-page {
    padding: 0 5%;

    .contact-page-body {
        margin-top: 5%;
        @include Flex;
        box-shadow: 2px 2px 10px rgba($color: #000000, $alpha: 0.3);
        padding: 5%;

        .contact-p-left {
            width: 40%;
            padding-right: 5%;
            @include Flex;
            flex-direction: column;
            border-right: 1px solid rgba($color: #000000, $alpha: 0.3);

            .contact-p-left-content {
                @include Flex;
                align-items: center;
                margin-bottom: 5%;

                .svg {
                    width: 30%;

                    svg {
                        width: 40px;
                        height: 40px;

                        path {
                            fill: $orange;
                        }
                    }
                }

                .contact-p-left-text {
                    width: 70%;
                    @include Flex;
                    flex-direction: column;

                    h6 {
                        font-weight: bold;
                        text-transform: capitalize;
                    }

                    a {
                        font-size: 14px;

                        &:hover {
                            transition: .2s all ease;
                            color: $orange;
                        }
                    }
                }
            }
        }

        .contact-p-right {
            width: 60%;
            padding: 0 5%;

            h5 {
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 5%;
                color: $orange;
            }

            form {
                @include Flex;
                flex-direction: column;

                input,
                textarea {
                    width: 100%;
                    font-size: 14px;
                    margin-bottom: 2%;
                    padding: 1%;
                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.7);
                    border-left: none;
                    border-top: none;
                    border-right: none;
                    outline: none;
                    color: $orange;
                }

                textarea {
                    height: 30vh;
                }

                button {
                    border: none;
                    color: #fff;
                    background: $orange;
                    border-radius: 10px;
                    width: 50%;
                    padding: 2%;
                }
            }
        }
    }

    .contact-map {
        margin: 5% 0;

        iframe {
            width: 100%;
            height: 400px;
        }
    }

}

/* ====================================================================
    Gallery
=====================================================================*/
.gallery-page {
    padding: 0 5%;

    .gallery-body {
        padding-top: 5%;

        .gallery-img {
            display: inline-block;
            margin: 20px;
            border: 1px solid black;
            overflow: hidden;
            width: calc(100% / 3.4);
            border: 3px solid $blue;

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                margin: 1%;
                display: block;
                transition: transform .4s;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                    transform-origin: 50% 50%;
                }
            }
        }


    }
}


/* ====================================================================
    Blog
=====================================================================*/
.blog {
    padding: $padding;
    margin-top: 5%;
    margin-bottom: 5%;

    .blog-body {
        @include Flex;
        flex-wrap: wrap;
        margin-top: 3%;
        justify-content: flex-start;

        .blog-card {
            width: calc(100% / 3.2);
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
            // border: 2px solid $blue;
            // border-radius: 20px;
            padding: 1%;
            margin: 1%;

            .blog-card-img {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.6);

                img {
                    width: 100%;
                    height: 30vh;
                    object-fit: cover;
                    border-radius: 20px 20px 0 0;
                }
            }

            .blog-card-text {
                padding: 5%;
                @include Flex;
                flex-direction: column;

                span {
                    font-size: 1vw;
                    color: $blue;
                    @include Flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 2%;

                    svg {
                        fill: $blue;
                        margin-right: 5%;
                    }
                }

                a {
                    font-size: 1.2vw;
                    font-weight: 700;
                    margin-bottom: 1%;

                    &:hover {
                        color: $blue;
                        transition: .5s all ease;
                    }
                }

                p {
                    font-size: 1vw;
                }
            }

            .blog-card-tags {
                @include Flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 0 5% 5% 5%;

                p {
                    // width: calc(100% /3.2);
                    background: $Bgrad;
                    color: #fff;
                    padding: 1% 2%;
                    margin: 1%;
                    border-radius: 5px;
                    font-size: .8vw;
                    text-transform: capitalize;

                    &:first-child {
                        background: none;
                        color: #000;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.blog-details {
    padding: $padding;
    margin-top: 10%;
    margin-bottom: 5%;

    .blog-details-img {
        padding: 0 5%;
        margin-bottom: 5%;

        img {
            width: 100%;
            border: 1px solid $blue;
        }

        span {
            font-size: 1vw;
            color: $blue;
            @include Flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 2%;

            svg {
                fill: $blue;
                margin-right: 5%;
            }
        }
    }

    .blog-details-text {
        padding: 0 5%;

        h1 {
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 5%;
            font-size: 2.5vw;
            line-height: 150%;
            font-weight: bold;
            color: $blue;
        }

        a {
            color: $blue;
            text-decoration: underline;
        }



    }
}

/* ====================================================================
    Privacy Policy
=====================================================================*/
.privacy-policy {
    padding: 5%;

    ul {
        padding-left: 5%;

        li {
            list-style-type: circle;
        }
    }

    h3 {
        margin-top: 3%;
    }

}


// Pages Ends*****************************************************************************************************