$blue: #4D8AE7;
$green: #78B748;
$orange: #EC7E2F;
$Bgrad: linear-gradient(90deg, rgba(123, 169, 240, 1) 0%, rgba(77, 138, 231, 1) 50%, rgba(63, 131, 236, 1) 100%);
$Ograd: linear-gradient(90deg, rgba(240, 162, 106, 1) 0%, rgba(236, 126, 47, 1) 50%, rgba(235, 114, 28, 1) 100%);
;
$Ggrad: linear-gradient(90deg, rgba(151, 212, 105, 1) 0%, rgba(120, 183, 72, 1) 50%, rgba(110, 200, 42, 1) 100%);
;

$OrangeGradient: linear-gradient(90deg, rgba(77, 138, 231, 1) 0%, rgba(248, 157, 76, 1) 100%, rgba(120, 183, 72, 1) 100%);
$OrangeTransparentGradient: linear-gradient(90deg, rgba(77, 138, 231, .5) 0%, rgba(248, 157, 76, .5) 100%, rgba(120, 183, 72, .5) 100%);
$greenGradient: linear-gradient(90deg, rgba(248, 157, 76, 1) 0%, rgba(77, 138, 231, 1) 0%, rgba(120, 183, 72, 1) 100%);
$greenTransparentGradient: linear-gradient(90deg, rgba(248, 157, 76, .5) 0%, rgba(77, 138, 231, .5) 0%, rgba(120, 183, 72, .5) 100%);
$HeaderGradient: linear-gradient(90deg, rgba(77, 138, 231, 1) 0%, rgba(248, 157, 76, 1) 100%, rgba(120, 183, 72, 1) 100%);
$mainFamily: 'Manrope', sans-serif;
$padding: 5% 5% 0 5%;

@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans&family=Signika&display=swap');

$title-family: 'Signika', sans-serif;
$text-family: 'Inclusive Sans', sans-serif;

$boxShadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);