@import 'variables.scss';
@import 'mixins.scss';

/*===============================================================
# Dashboard
===============================================================*/
.dashboard {
    display: flex;
    justify-content: flex-end;
}

.dashboard-app {
    width: 75%;
    padding: 2%;

    h3 {
        text-align: center;
        font-size: 2vw;
        text-transform: capitalize
    }
}

/*===============================================================
# Login
===============================================================*/

.admin-login {
    @include Flex;
    background: $blue;
    color: #fff;

    img {
        width: 100%;
        object-fit: cover;
    }

    .admin-login-content {
        background: rgba($color: #000, $alpha: 0.3);
        width: 40%;
        padding: 5%;
        @include Flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;

        form {
            width: 100%;
            display: flex;
            flex-direction: column;

            h5 {
                font-size: 2.5vw;
                text-align: center;
                margin-bottom: 5%;
            }

            span {
                font-size: 1.4vw;
                margin-bottom: 5%;
                text-shadow: 0px 0px 0px #000;

            }

            input {
                font-size: 1.4vw;
                margin-bottom: 5%;
                border: none;
                padding: 3%;
                border-radius: 5px;
                // border: 2px solid $blue;
                outline: none;
                color: $blue;
            }

            .login-button {
                background: $Bgrad;
                color: #fff;
                border: none;
                font-size: 1.5vw;
                margin-top: 5%;

            }
        }
    }
}

/*===============================================================
# Admin Dashbaord
===============================================================*/

.dashboard-nav {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 25%;
    background: $Bgrad;
    color: #ffff;
    padding: 3%;


    .a-logo {
        background: #fff;
        display: block;
        margin-bottom: 5%;
        padding: 8%;
        border-radius: 5px;

        img {
            width: 100%;
        }
    }

    .dashboard-nav-list {
        @include Flex;
        flex-direction: column;
        margin-top: 20%;

        .dashboard-nav-item {
            margin-bottom: 5%;
            font-size: 1.3vw;
            text-transform: capitalize;
            padding: 3%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 5%;
                fill: #fff;
            }
        }
    }

    .admin-blog {
        margin-bottom: 5%;
        font-size: 1.3vw;
        text-transform: capitalize;
        padding: 3%;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5%;
            fill: #fff;
        }

        .admin-blog-nav {
            @include Flex;
            flex-direction: column;
            color: #fff;
            margin-top: 5%;
            padding-top: 5%;
            padding-left: 5%;
            border-top: 1px solid #fff;
            height: 100%;

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2%;
                margin: 0;
                font-size: 1.2vw;

                &:hover {
                    transform: scale(1.1) translateX(15px);
                    transition: .5s all ease;
                    background: transparent;
                }
            }


        }
    }

    button {
        width: 90%;
        margin: 0 5%;
        border: none;
        border-top: 1px solid #fff;
        padding: 5%;
        position: absolute;
        bottom: 0;
        right: 0;
        background: transparent;
        color: #fff;
        font-size: 1.5vw;

        svg {
            width: 20px;
            height: 20px;
            margin-left: 5%;
            fill: #fff;
        }
    }
}

/*===============================================================
# Blog- Create
===============================================================*/
.blog-create {

    form {
        display: flex;
        flex-direction: column;
        margin: 5% 0;
        // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 2%;

        .__input {
            display: flex;
            justify-content: space-between;

            label {
                font-size: 1.2vw;
                text-transform: capitalize;
                width: 30%;
            }

            .b__input {
                width: 100%;

                span {
                    font-size: 1vw;
                    color: red;
                }
            }
        }

        input,
        textarea {
            font-size: 1vw;
            border: none;
            padding: 1% 2%;
            border-radius: 5px;
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            outline: none;
            color: #000;
            width: 100%;
            margin-bottom: 2%;
        }

        button {
            background: $Bgrad;
            color: #fff;
            border: none;
            font-size: 1.1vw;
            padding: 1%;
            border-radius: 5px;
            margin-top: 10%;
            width: 100%;
        }
    }


}

/*===============================================================
# Blog- List
===============================================================*/
.blog-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5% 0;

    .blog-card {
        width: calc(100% / 3.2);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        margin: 1%;

        position: relative;

        img {
            width: 100%;
        }


        .blog-card-text {
            margin-bottom: 20%;
            padding: 5%;

            p {
                font-size: 1.1vw;
                font-weight: bold;
                margin-bottom: 1%;
            }

            span {
                font-size: .9vw;
                color: $blue;

                svg {
                    width: 10px;
                    height: 10px;
                    fill: $blue;
                    margin-right: 5%;
                }
            }
        }

        .blog-card-button {
            display: flex;
            justify-content: center;
            align-items: stretch;
            position: absolute;
            bottom: 5%;
            left: 0;
            width: 100%;

            a,
            button {
                width: calc(100% / 2.2);
                border: none;
                padding: 2%;
                border-radius: 5px;
                font-size: 1vw;
                text-transform: capitalize;
                text-align: center;
                margin: 1%;
                background: $Bgrad;
                color: #fff;

                &:hover {
                    background: transparent;
                    color: #000;
                    transition: .2s all ease-in-out;
                    border: 1px solid $blue;
                }
            }
        }
    }

    p {
        font-size: 1.2vw;
    }
}

/*===============================================================
# Contact DB
===============================================================*/
.contact-form {
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 50px auto;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #eee;
    }

    th {
        background: $blue;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-size: 1.1vw;
    }

    td {
        background: #fff;
    }

    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */
    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {

        table {
            width: 100%;
        }

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            /* Label the data */
            content: attr(data-column);

            color: #000;
            font-weight: bold;
        }

    }
}

/*===============================================================
# Reset Password
===============================================================*/
.admin-settings {
    display: flex;
    flex-direction: column;
    margin: 10% auto 0 auto;
    width: 80%;


    input {
        font-size: 1.2vw;
        border: none;
        padding: 1% 2%;
        border-radius: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        outline: none;
        color: #000;
        width: 100%;
        margin-bottom: 1%;
    }

    button {
        background: $Bgrad;
        color: #fff;
        border: none;
        font-size: 1.3vw;
        padding: 1%;
        border-radius: 5px;
        margin-top: 1%;
        width: 100%;
    }
}