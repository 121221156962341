$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@import 'variables.scss';

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    /* ====================================================================
    Common
    =====================================================================*/
    .heading {

        h1,
        h3 {
            font-size: 7vw;
            width: 100%;

            &:before {
                bottom: 3px;
            }
        }
    }

    .banner {
        margin-top: 24%;

        .banner-caption {
            h6 {
                font-size: 8vw;
                top: 40%;

                &:after {
                    margin-top: 3%;
                }

            }
        }

        .button {
            width: 25%;
            height: 25%;
            top: 33%;

            button {
                font-size: 3.2vw;
            }
        }
    }

    .whatsapp {
        width: 35%;
        padding: 2%;

        h6 {
            font-size: 4vw;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10%;
        }
    }

    .modal {
        .modal-header {
            h6 {
                font-size: 6vw;
                text-shadow: none;
            }
        }

        form {

            input,
            select,
            textarea {
                font-size: 4vw;
            }

            button {
                font-size: 5vw;
            }

            .drop {
                .drop-btn {
                    font-size: 4vw;
                }

                .drop-1 {
                    .drop-content {
                        width: 40%;

                        h6 {
                            font-size: 3.5vw;
                        }

                        .d-m {
                            left: 36%;
                            width: 60%;
                            padding: 2% 5%;

                            p {
                                font-size: 3.2vw;
                            }
                        }
                    }
                }
            }

        }
    }



    /* ====================================================================
    header
    =====================================================================*/
    .header {
        justify-content: space-between;

        .logo {
            width: 50%;
        }

        .header-links {
            display: none;
        }

        .header-menu {
            width: 10%;
            margin-right: 5%;

            svg {
                display: block;
                width: 50px;
                height: 50px;
            }
        }
    }

    /* ====================================================================
    Slider
    =====================================================================*/
    .slider {
        margin-top: 24%;

        .form {
            width: 100%;
            border-radius: 0;
            background: $blue;
            top: 89%;
            left: 0;
            height: 250%;

            h3 {
                font-size: 5vw;
            }

            form {

                input,
                textarea,
                select {
                    font-size: 4vw;
                }

                .drop {
                    .drop-btn {
                        font-size: 4vw;
                    }

                    .drop-1 {
                        .drop-content {
                            width: 40%;

                            h6 {
                                font-size: 3.5vw;
                            }

                            .d-m {
                                left: 36%;
                                width: 60%;
                                padding: 2% 5%;

                                p {
                                    font-size: 3.2vw;
                                }
                            }
                        }
                    }
                }

            }
        }

        .carousel {
            .control-dots {
                display: none;
            }

            .carousel-content {
                position: relative;

                .carousel-body {
                    flex-direction: column;
                    position: unset;
                    transform: none;

                    .carousel-text {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        h3,
                        p,
                        a {
                            font-size: 2.5vw;
                        }

                        span {
                            font-size: 3.5vw;
                        }
                    }


                }
            }
        }
    }

    /* ====================================================================
    Courses
    =====================================================================*/
    .courses {
        margin-top: 120%;

        .courses-body {
            .courses-left {
                p {
                    font-size: 3.5vw;
                }
            }

            .courses-right {
                flex-wrap: wrap;

                .courses-right-card {
                    width: 100%;

                    .courses-right-card-caption {
                        h3 {
                            font-size: 8vw;
                        }
                    }
                }
            }
        }
    }

    /* ====================================================================
    achievements
    =====================================================================*/
    .achievements {
        .achievements-body {
            flex-wrap: wrap;

            .achievements-card {
                width: 100%;

                p {
                    margin-bottom: 5%;
                    font-size: 6vw;
                }

                h4 {
                    font-size: 5.4vw;
                }
            }
        }
    }

    /* ====================================================================
    Video gallery
    =====================================================================*/
    .video-gallery {
        .video-gallery-body {

            .video-card {
                width: 100%;
            }
        }

    }

    /* ====================================================================
    Recognization
    =====================================================================*/
    .recognization {
        .cer-body {
            flex-wrap: wrap;

            .cer-body-img {
                #Frames {
                    width: calc(100%);
                }
            }

            .first,
            .second {
                width: 100%;
            }
        }
    }

    /* ====================================================================
    Home-Blogs
    =====================================================================*/
    .blogs {

        .blogs-body {
            flex-direction: column;

            .blogs-body-content {
                width: 100%;
                margin-bottom: 10%;

                .blogs-caption {
                    h3 {
                        font-size: 4vw;
                    }

                    button {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Home book
    =====================================================================*/
    .home-book {
        .book-body {
            flex-direction: column;



            .book {
                flex-direction: column;
                width: 100%;

                .book-img {
                    width: 100%;
                    margin: 0 5% 5% 0;

                    img {
                        width: 90%;
                    }
                }

                &:nth-child(1) {
                    .book-caption {
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }

                .book-caption {
                    width: 100%;
                    margin-top: 5%;
                    padding: 0 5%;

                    h4 {
                        font-size: 5vw;
                        margin: 0;
                    }

                    span {
                        font-size: 4.5vw;
                        margin-bottom: 5%;
                    }

                    p {
                        font-size: 4vw;
                        margin: 2% 0;

                    }

                    a {
                        font-size: 3.3vw;
                    }
                }
            }
        }

    }

    /* ====================================================================
    footer
    =====================================================================*/
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 4.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 4vw;
                }
            }

            .footer-links {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 3.5vw;
                    }
                }
            }

            .footer-contact {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-contact-body {
                    justify-content: flex-start;

                    .footer-contact-content {
                        a {
                            font-size: 3.5vw;

                        }
                    }
                }
            }
        }

        .footer-follow {
            margin-bottom: 5%;

            .svg {
                width: 40px;
                height: 40px;
            }
        }

        .footer-copyright {
            flex-direction: column;
            text-align: center;

            p {
                font-size: 3.5vw;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: 3.5vw;
                }
            }
        }
    }


    /* ====================================================================
    About Page
    =====================================================================*/
    .about-page {
        .about-page-body {
            flex-direction: column;

            .about-p-img,
            .about-content {
                width: 100%;
            }

            .about-content {
                margin-top: 5%;
                padding-left: 0;

                p {
                    font-size: 3.5vw;
                }
            }
        }

        .vision-mission,
        .values {
            flex-direction: column;

            .vision-content,
            .values-content,
            .mission-content {
                width: 100%;
                margin: 3% 0;

                h3 {
                    font-size: 5vw;
                    margin-bottom: 0;
                    margin-top: 4%;
                }

                p {
                    font-size: 3.8vw;
                    margin-top: 10%;
                    padding: 0 5%;
                }
            }
        }
    }

    /* ====================================================================
    Gallery Page
    =====================================================================*/
    .gallery-page {
        .gallery-body {
            .gallery-img {
                width: calc(100% / 2.1);
                margin: 1%;
            }
        }
    }

    /* ====================================================================
    Contact Page
    =====================================================================*/
    .contact-page {
        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
                padding: 5% 0;

                .contact-p-left-content {
                    .svg {
                        width: 20%;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .contact-p-left-text {
                        a {
                            font-size: 3.2vw;
                        }
                    }
                }
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                form {
                    input {
                        font-size: 3.5vw;
                        margin-bottom: 5%;
                    }

                    button {
                        font-size: 3.5vw;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Service Page
    =====================================================================*/
    .service-page {
        .training-body {
            h4 {
                font-size: 6vw;
            }

            .training-intro {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    img {
                        border: 5px solid $blue;
                    }

                    .img-before {
                        display: none;
                    }
                }

                .training-intro-caption {
                    margin-top: 5%;
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                    }

                    ul {
                        li {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .training-types {
                flex-wrap: wrap;

                .corporate-training,
                .individual-training {
                    width: 100%;
                    margin: 3% 0;

                    h6 {
                        font-size: 5vw;
                    }
                }
            }

            .outcome {
                .training-intro-caption {
                    ul {
                        li {
                            width: 100%;
                            height: 100%;

                            p {
                                font-size: 4vw;
                            }
                        }
                    }
                }
            }
        }
    }


    /* ====================================================================
    Training Page
    =====================================================================*/
    .corporate {

        .corporate-body {
            .corporate-card {
                width: 100%;
                margin-bottom: 5%;

                h3 {
                    font-size: 4.5vw;
                    line-height: 150%;
                }
            }

            .last {
                width: 100%;

                .corporate-card-img {
                    img {
                        width: 100%;
                    }

                    h3 {
                        font-size: 2.5vw;
                    }
                }

                .corporate-card-text {
                    margin-top: 5%;

                    ul {
                        li {
                            font-size: 2vw;

                            &:first-child {
                                height: 15vh;
                                margin-bottom: -70%;
                            }

                            &:nth-child(2) {
                                height: 12vh;
                            }

                            &:nth-child(3) {
                                height: 8vh;
                            }

                            &:last-child {
                                height: 5vh;
                            }
                        }
                    }
                }
            }
        }
    }

    .usp {
        margin: 20% 0;

        .usp-card {
            flex-direction: column;

            .usp-svg {
                width: 40px;
                height: 40px;
                rotate: 360deg;

                svg {
                    width: 20px;
                    height: 20px;

                }
            }

            .usp-text {
                width: 180%;

                p {
                    font-size: 2.5vw;

                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

        }
    }

    /* ====================================================================
    Blog Page
    =====================================================================*/

    .blog {
        margin-top: 25%;
        margin-bottom: 5%;

        .blog-body {
            .blog-card {
                width: 100%;

                .blog-card-text {
                    span {
                        font-size: 3.5vw;
                    }

                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        margin-top: 25%;

        .blog-details-img {
            padding: 0;

            span {
                margin-top: 5%;
                font-size: 3.5vw;
            }

        }

        .blog-details-text {
            padding: 0;

            h1 {
                font-size: 5vw;
            }

            p {
                font-size: 4vw;

                strong {
                    font-size: 4.3vw;
                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    /* ====================================================================
    Common
    =====================================================================*/
    .heading {
        h1 {
            font-size: 4vw;
            width: 100%;

            &:before {
                bottom: 3px;
            }
        }
    }

    .banner {
        margin-top: 15%;

        .banner-caption {
            h6 {
                font-size: 8vw;
                top: 40%;

                &:after {
                    margin-top: 3%;
                }
            }
        }

        .button {
            width: 25%;
            height: 25%;
            top: 33%;

            button {
                font-size: 2.8vw;
            }
        }
    }

    .whatsapp {
        width: 25%;
        padding: 2%;

        h6 {
            font-size: 3vw;
        }

        svg {
            margin-right: 10%;
            width: 25px;
            height: 25px;
        }
    }

    .project {
        .project-caption {
            h6 {
                font-size: 3.5vw;
            }

            .button {
                width: 60%;
                margin-top: 0;

                button {
                    font-size: 3vw;

                }
            }
        }
    }

    .modal {
        .modal-header {
            h6 {
                font-size: 5vw;
                text-shadow: none;
            }
        }

        form {

            input,
            select,
            textarea {
                font-size: 3vw;
            }

            button {
                font-size: 4vw;
            }


            .drop {
                .drop-btn {
                    font-size: 3vw;
                }

                .drop-1 {
                    .drop-content {
                        width: 40%;

                        h6 {
                            font-size: 2.5vw;
                        }

                        .d-m {
                            left: 36%;
                            width: 60%;
                            padding: 2% 5%;

                            p {
                                font-size: 2.5vw;
                            }
                        }
                    }
                }
            }
        }
    }



    /* ====================================================================
    header
    =====================================================================*/
    .header {
        justify-content: space-between;

        .logo {
            width: 30%;
        }

        .header-links {
            display: none;
        }

        .header-menu {
            width: 10%;

            svg {
                display: block;
                width: 40px;
                height: 40px;
            }
        }
    }

    .header-mobile {
        .header-mobile-links {
            .serv {
                font-size: 5vw;

                #services {
                    a {
                        font-size: 4vw;
                    }
                }
            }

            a {
                font-size: 5vw;

            }
        }
    }

    /* ====================================================================
    Slider
    =====================================================================*/
    .slider {
        margin-top: 15%;

        .form {
            width: 100%;
            border-radius: 0;
            background: $blue;
            top: 89%;
            left: 0;
            height: 210%;

            h3 {
                font-size: 5vw;
            }

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }

                .drop {
                    .drop-btn {
                        font-size: 3vw;
                    }

                    .drop-1 {
                        .drop-content {
                            width: 40%;

                            h6 {
                                font-size: 2.5vw;
                            }

                            .d-m {
                                left: 36%;
                                width: 60%;
                                padding: 2% 5%;

                                p {
                                    font-size: 2.5vw;
                                }
                            }
                        }
                    }
                }

            }
        }

        .carousel {
            .control-dots {
                display: none;
            }

            .carousel-content {
                position: relative;

                .carousel-body {
                    flex-direction: column;
                    position: unset;
                    transform: none;

                    .carousel-text {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        h3,
                        p,
                        a {
                            font-size: 2.5vw;
                        }

                        span {
                            font-size: 3.5vw;
                        }
                    }


                }
            }
        }
    }

    /* ====================================================================
    Courses
    =====================================================================*/
    .courses {
        margin-top: 90%;

        .courses-body {
            .courses-left {
                p {
                    font-size: 2.5vw;
                }
            }
        }
    }

    /* ====================================================================
    achievements
    =====================================================================*/
    .achievements {
        .achievements-body {
            flex-wrap: wrap;

            .achievements-card {
                width: calc(100% / 2.3);

                p {
                    margin-bottom: 5%;
                    font-size: 3vw;
                }

                h4 {
                    font-size: 2.4vw;
                }
            }
        }
    }

    /* ====================================================================
    Video gallery
    =====================================================================*/
    .video-gallery {
        .carousel {
            height: 50vh;

            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;

                    .testimonial-card {
                        height: 20vh;
                        width: 80%;
                        margin: 1% auto;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Blogs
    =====================================================================*/
    .blogs {
        .blogs-body {
            flex-direction: column;

            .blogs-body-content {
                width: 100%;
                margin-bottom: 10%;

                .blogs-caption {
                    h3 {
                        font-size: 4vw;
                    }

                    button {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Home book
    =====================================================================*/
    .home-book {
        .book-body {
            flex-direction: column;



            .book {
                flex-direction: column;
                width: 100%;

                .book-img {
                    width: 100%;
                    margin: 0 5% 5% 0;

                    img {
                        width: 90%;
                    }
                }

                &:nth-child(1) {
                    .book-caption {
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }

                .book-caption {
                    width: 100%;
                    margin-top: 5%;
                    padding: 0 5%;

                    h4 {
                        font-size: 5vw;
                        margin: 0;
                    }

                    span {
                        font-size: 3.5vw;
                        margin-bottom: 5%;
                    }

                    p {
                        font-size: 3vw;
                        margin: 2% 0;

                    }

                    a {
                        font-size: 3.3vw;
                    }
                }
            }
        }

    }

    /* ====================================================================
    footer
    =====================================================================*/
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 4.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 4vw;
                }
            }

            .footer-links {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 3.5vw;

                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .footer-contact {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-contact-body {
                    justify-content: flex-start;

                    .footer-contact-content {
                        svg {
                            width: 20px;
                            height: 20px;
                        }

                        a {
                            font-size: 3.5vw;

                        }
                    }
                }
            }
        }

        .footer-follow {
            margin-bottom: 5%;

            .svg {
                width: 40px;
                height: 40px;
            }
        }

        .footer-copyright {
            flex-direction: column;
            text-align: center;

            p {
                font-size: 3.5vw;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: 3.5vw;
                }
            }
        }
    }

    /* ====================================================================
    About Page
    =====================================================================*/
    .about-page {
        .about-page-body {
            flex-direction: column;

            .about-p-img,
            .about-content {
                width: 100%;
            }

            .about-content {
                margin-top: 5%;
                padding-left: 0;

                p {
                    font-size: 2.5vw;
                }
            }
        }

        .vision-mission,
        .values {
            flex-direction: column;

            .vision-content,
            .values-content,
            .mission-content {
                width: 100%;
                margin: 3% 0;

                h3 {
                    font-size: 4vw;
                    margin-bottom: 0;
                    margin-top: 4%;
                }

                p {
                    font-size: 2.8vw;
                    margin-top: 10%;
                    padding: 0 5%;
                }
            }
        }
    }

    /* ====================================================================
    Gallery Page
    =====================================================================*/
    .gallery-page {
        .gallery-body {
            .gallery-img {
                width: calc(100% / 2.1);
                margin: 1%;
            }
        }
    }

    /* ====================================================================
    Contact Page
    =====================================================================*/
    .contact-page {
        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
                padding: 5% 0;

                .contact-p-left-content {
                    .svg {
                        width: 20%;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .contact-p-left-text {
                        a {
                            font-size: 2.5vw;
                        }
                    }
                }
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                form {
                    input {
                        font-size: 2.5vw;
                        margin-bottom: 5%;
                    }

                    button {
                        font-size: 2.5vw;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Service Page
    =====================================================================*/
    .service-page {
        .training-body {
            h4 {
                font-size: 6vw;
            }

            .training-intro {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    img {
                        border: 5px solid $blue;
                    }

                    .img-before {
                        display: none;
                    }
                }

                .training-intro-caption {
                    margin-top: 5%;
                    width: 100%;

                    p {
                        font-size: 2.5vw;
                        line-height: 160%;
                    }

                    ul {
                        li {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .outcome {
                .training-intro-caption {
                    ul {
                        li {
                            width: calc(100% / 2.1);

                            p {
                                font-size: 2.8vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .usp {
        margin: 15% 0;

        .usp-card {
            flex-direction: column;

            .usp-svg {
                width: 40px;
                height: 40px;
                rotate: 360deg;

                svg {
                    width: 20px;
                    height: 20px;

                }
            }

            .usp-text {
                p {
                    font-size: 2vw;

                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

        }
    }

    /* ====================================================================
    Blog Page
    =====================================================================*/

    .blog {
        margin-top: 25%;
        margin-bottom: 5%;

        .blog-body {
            .blog-card {
                width: 100%;

                .blog-card-text {
                    span {
                        font-size: 3.5vw;
                    }

                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        margin-top: 25%;

        .blog-details-img {
            padding: 0;

            span {
                margin-top: 5%;
                font-size: 3.5vw;
            }

        }

        .blog-details-text {
            padding: 0;

            h1 {
                font-size: 5vw;
            }

            p {
                font-size: 4vw;

                strong {
                    font-size: 4.3vw;
                }
            }
        }
    }

    /* ====================================================================
    Recognization
    =====================================================================*/
    .recognization {
        .cer-body {

            .cer-body-img {
                #Frames {
                    width: calc(100%);
                }
            }

            .first {
                width: 40%;
            }

            .second {
                width: 68%;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    /* ====================================================================
    Common
    =====================================================================*/
    .heading {

        h1,
        h3 {
            font-size: 4vw;
            width: 100%;

            &:before {
                bottom: 3px;
            }
        }
    }

    .banner {
        margin-top: 15%;

        .banner-caption {
            h6 {
                font-size: 8vw;
                top: 40%;

                &:after {
                    margin-top: 3%;
                }

            }
        }

        .button {
            width: 25%;
            height: 25%;
            top: 33%;

            button {
                font-size: 2.8vw;
            }
        }
    }

    .whatsapp {
        width: 25%;
        padding: 2%;

        h6 {
            font-size: 3vw;
        }

        svg {
            margin-right: 10%;
            width: 25px;
            height: 25px;
        }
    }


    .modal {
        .modal-header {
            h6 {
                font-size: 5vw;
                text-shadow: none;
            }
        }

        form {

            input,
            select,
            textarea {
                font-size: 3vw;
            }

            button {
                font-size: 4vw;
            }

            .drop {
                .drop-btn {
                    font-size: 3vw;
                }

                .drop-1 {
                    .drop-content {
                        width: 40%;

                        h6 {
                            font-size: 2.5vw;
                        }

                        .d-m {
                            left: 36%;
                            width: 60%;
                            padding: 2% 5%;

                            p {
                                font-size: 2.5vw;
                            }
                        }
                    }
                }
            }

            .dropdown-toggle {
                font-size: 3vw;
            }
        }
    }

    .drop {
        .drop-btn {
            font-size: 3vw;
        }

        .drop-1 {
            .drop-content {
                width: 40%;

                h6 {
                    font-size: 2.5vw;
                }

                .d-m {
                    left: 36%;
                    width: 60%;
                    padding: 2% 5%;

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }

    /* ====================================================================
    header
    =====================================================================*/
    .header {
        justify-content: space-between;

        .logo {
            width: 30%;
        }

        .header-links {
            display: none;
        }

        .header-menu {
            width: 10%;

            svg {
                display: block;
                width: 40px;
                height: 40px;
            }
        }
    }

    .header-mobile {
        .header-mobile-links {
            .serv {
                font-size: 5vw;

                #services {
                    a {
                        font-size: 4vw;
                    }
                }
            }

            a {
                font-size: 5vw;

            }
        }
    }

    /* ====================================================================
    Slider
    =====================================================================*/
    .slider {
        margin-top: 15%;

        .form {
            width: 100%;
            border-radius: 0;
            background: $blue;
            top: 89%;
            left: 0;
            height: 220%;

            h3 {
                font-size: 5vw;
            }

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }

                .drop {
                    .drop-btn {
                        font-size: 3vw;
                    }

                    .drop-1 {
                        .drop-content {
                            width: 40%;

                            h6 {
                                font-size: 2.5vw;
                            }

                            .d-m {
                                left: 36%;
                                width: 60%;
                                padding: 2% 5%;

                                p {
                                    font-size: 2.5vw;
                                }
                            }
                        }
                    }
                }


                .dropdown-toggle {
                    font-size: 3vw;
                }

                .dropdown-menu {
                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .carousel {
            .control-dots {
                display: none;
            }

            .carousel-content {
                position: relative;

                .carousel-body {
                    flex-direction: column;
                    position: unset;
                    transform: none;

                    .carousel-text {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        h3,
                        p,
                        a {
                            font-size: 2.5vw;
                        }

                        span {
                            font-size: 3.5vw;
                        }
                    }


                }
            }
        }
    }

    /* ====================================================================
    Courses
    =====================================================================*/
    .courses {
        margin-top: 100%;

        .courses-body {
            .courses-left {
                p {
                    font-size: 3.5vw;
                }
            }

            .courses-right {
                flex-wrap: wrap;

                .courses-right-card {
                    width: 100%;

                    .courses-right-card-caption {
                        h3 {
                            font-size: 8vw;
                        }
                    }
                }
            }
        }
    }

    /* ====================================================================
    achievements
    =====================================================================*/
    .achievements {
        .achievements-body {
            flex-wrap: wrap;

            .achievements-card {
                width: 100%;

                p {
                    margin-bottom: 5%;
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4.4vw;
                }
            }
        }
    }

    /* ====================================================================
    Video gallery
    =====================================================================*/
    .video-gallery {
        .video-gallery-body {

            .video-card {
                width: calc(100% / 2.1);
                height: 25vh;
            }
        }

    }

    /* ====================================================================
    Recognization
    =====================================================================*/
    .recognization {
        .cer-body {
            flex-wrap: wrap;

            .cer-body-img {
                #Frames {
                    width: calc(100%);
                }
            }

            .first,
            .second {
                width: 100%;
            }
        }
    }

    /* ====================================================================
    Blogs
    =====================================================================*/
    .blogs {
        .blogs-body {
            flex-direction: column;

            .blogs-body-content {
                width: 100%;
                margin-bottom: 10%;

                .blogs-caption {
                    h3 {
                        font-size: 4vw;
                    }

                    button {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Home book
    =====================================================================*/
    .home-book {
        .book-body {
            flex-direction: column;

            .book {
                flex-direction: column;
                width: 100%;

                .book-img {
                    width: 100%;
                    margin: 0 5% 5% 0;

                    img {
                        width: 90%;
                    }
                }

                &:nth-child(1) {
                    .book-caption {
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }

                .book-caption {
                    width: 100%;
                    margin-top: 5%;
                    padding: 0 5%;

                    h4 {
                        font-size: 5vw;
                        margin: 0;
                    }

                    span {
                        font-size: 3.5vw;
                        margin-bottom: 5%;
                    }

                    p {
                        font-size: 3vw;
                        margin: 2% 0;

                    }

                    a {
                        font-size: 3.3vw;
                    }
                }
            }
        }

    }


    /* ====================================================================
    footer
    =====================================================================*/
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 4.5vw;
                    margin-bottom: 2%;
                }

                p {
                    font-size: 4vw;
                }
            }

            .footer-links {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-links-content {
                    a {
                        font-size: 3.5vw;
                    }
                }
            }

            .footer-contact {
                h4 {
                    font-size: 4.5vw;
                    margin-bottom: 3%;
                }

                .footer-contact-body {
                    justify-content: flex-start;

                    .footer-contact-content {
                        a {
                            font-size: 3.5vw;

                        }
                    }
                }
            }
        }

        .footer-follow {
            margin-bottom: 5%;

            .svg {
                width: 40px;
                height: 40px;
            }
        }

        .footer-copyright {
            flex-direction: column;
            text-align: center;

            p {
                font-size: 3.5vw;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: 3.5vw;
                }
            }
        }
    }

    /* ====================================================================
    About Page
    =====================================================================*/
    .about-page {
        .about-page-body {
            flex-direction: column;

            .about-p-img,
            .about-content {
                width: 100%;
            }

            .about-content {
                margin-top: 5%;
                padding-left: 0;

                p {
                    font-size: 2.5vw;
                }
            }
        }

        .vision-mission,
        .values {
            flex-direction: column;

            .vision-content,
            .values-content,
            .mission-content {
                width: 100%;
                margin: 3% 0;

                h3 {
                    font-size: 4vw;
                    margin-bottom: 0;
                    margin-top: 4%;
                }

                p {
                    font-size: 2.8vw;
                    margin-top: 10%;
                    padding: 0 5%;
                }
            }
        }
    }

    /* ====================================================================
    Gallery Page
    =====================================================================*/
    .gallery-page {
        .gallery-body {
            .gallery-img {
                width: calc(100% / 2.1);
                margin: 1%;
            }
        }
    }

    /* ====================================================================
    Contact Page
    =====================================================================*/
    .contact-page {
        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
                padding: 5% 0;

                .contact-p-left-content {
                    .svg {
                        width: 20%;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .contact-p-left-text {
                        a {
                            font-size: 2.5vw;
                        }
                    }
                }
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                form {
                    input {
                        font-size: 2.5vw;
                        margin-bottom: 5%;
                    }

                    button {
                        font-size: 2.5vw;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    /* ====================================================================
    Service Page
    =====================================================================*/
    .service-page {
        .training-body {
            h4 {
                font-size: 6vw;
            }

            .training-intro {
                flex-direction: column;

                .training-img {
                    width: 100%;

                    img {
                        border: 5px solid $blue;
                    }

                    .img-before {
                        display: none;
                    }
                }

                .training-intro-caption {
                    margin-top: 5%;
                    width: 100%;

                    p {
                        font-size: 2.5vw;
                        line-height: 160%;
                    }

                    ul {
                        li {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .training-types {
                flex-wrap: wrap;

                .corporate-training,
                .individual-training {
                    width: 100%;
                    margin: 3% 0;

                    h6 {
                        font-size: 5vw;
                    }
                }
            }

            .outcome {
                .training-intro-caption {
                    ul {
                        li {
                            width: calc(100% / 2.1);
                            height: 35vh;

                            p {
                                font-size: 2.8vw;
                            }
                        }
                    }
                }
            }


        }
    }

    /* ====================================================================
    Training Page
    =====================================================================*/
    .corporate {
        padding: 0 5% 2% 2%;

        .corporate-body {
            .corporate-card {
                width: calc(100% / 2.1);

                h3 {
                    font-size: 2.5vw;
                    line-height: 150%;
                }
            }

            .last {
                width: 100%;

                .corporate-card-img {
                    img {
                        width: 100%;
                    }

                    h3 {
                        font-size: 2.5vw;
                    }
                }

                .corporate-card-text {
                    margin-top: 5%;

                    ul {
                        li {
                            font-size: 2vw;

                            &:first-child {
                                height: 20vh;
                            }

                            &:nth-child(2) {
                                height: 15vh;
                            }

                            &:nth-child(3) {
                                height: 10vh;
                            }

                            &:last-child {
                                height: 5vh;
                            }
                        }
                    }
                }
            }
        }
    }

    .usp {
        margin: 15% 0;

        .usp-card {
            flex-direction: column;

            .usp-svg {
                width: 40px;
                height: 40px;
                rotate: 360deg;

                svg {
                    width: 20px;
                    height: 20px;

                }
            }

            .usp-text {

                p {
                    font-size: 2vw;

                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

        }
    }

    /* ====================================================================
    Blog Page
    =====================================================================*/

    .blog {
        margin-top: 25%;
        margin-bottom: 5%;

        .blog-body {
            .blog-card {
                width: 100%;

                .blog-card-text {
                    span {
                        font-size: 3.5vw;
                    }

                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        margin-top: 25%;

        .blog-details-img {
            padding: 0;

            span {
                margin-top: 5%;
                font-size: 3.5vw;
            }

        }

        .blog-details-text {
            padding: 0;

            h1 {
                font-size: 5vw;
            }

            p {
                font-size: 4vw;

                strong {
                    font-size: 4.3vw;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {


    .usp {
        margin: 10% 0;

        .usp-card {
            .usp-text {
                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .service-page {
        .training-body {
            .outcome {
                .training-intro-caption {
                    ul {
                        li {
                            height: 28vh;

                            p {
                                font-size: 1.5vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .corporate {
        .corporate-body {
            .last {
                width: 100%;

                .corporate-card-img {
                    img {
                        width: 100%;
                    }

                    h3 {
                        font-size: 2.5vw;
                    }
                }

                .corporate-card-text {
                    margin-top: 5%;

                    ul {
                        li {
                            font-size: 2vw;

                            &:first-child {
                                height: 25vh;
                            }

                            &:nth-child(2) {
                                height: 20vh;
                            }

                            &:nth-child(3) {
                                height: 15vh;
                            }

                            &:last-child {
                                height: 10vh;
                            }
                        }
                    }
                }
            }
        }
    }

    .slider {
        .form {
            form {
                textarea {
                    height: 12vh;
                }
            }
        }
    }

    /* ====================================================================
    Blog Page
    =====================================================================*/

    .blog {
        margin-top: 10%;
        margin-bottom: 5%;

        .blog-body {


            .blog-card {
                width: calc(100% / 2.2);

                .blog-card-img {
                    img {
                        height: 100%;
                    }
                }

                .blog-card-text {
                    span {
                        font-size: 2vw;
                    }

                    a {
                        font-size: 2.3vw;
                    }
                }
            }
        }
    }

    .blog-details {
        margin-top: 25%;

        .blog-details-img {
            padding: 0;

            span {
                margin-top: 5%;
                font-size: 3.5vw;
            }

        }

        .blog-details-text {
            padding: 0;

            h1 {
                font-size: 5vw;
            }

            p {
                font-size: 4vw;

                strong {
                    font-size: 4.3vw;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    .modal {
        .modal-header {
            h6 {
                font-size: 5vw;
                text-shadow: none;
            }
        }

        form {

            input,
            select,
            textarea {
                font-size: 3vw;
            }

            button {
                font-size: 4vw;
            }

            .dropdown-toggle {
                font-size: 3vw;
            }
        }
    }

    .usp {
        margin: 10% 0;

        .usp-card {
            .usp-text {
                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .service-page {
        .training-body {
            .outcome {
                .training-intro-caption {
                    ul {
                        li {
                            height: 28vh;

                            p {
                                font-size: 1.5vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .corporate {
        .corporate-body {
            .last {
                width: 100%;

                .corporate-card-img {
                    img {
                        width: 100%;
                    }

                    h3 {
                        font-size: 2.5vw;
                    }
                }

                .corporate-card-text {
                    margin-top: 5%;

                    ul {
                        li {
                            font-size: 2vw;

                            &:first-child {
                                height: 25vh;
                            }

                            &:nth-child(2) {
                                height: 20vh;
                            }

                            &:nth-child(3) {
                                height: 15vh;
                            }

                            &:last-child {
                                height: 10vh;
                            }
                        }
                    }
                }
            }
        }
    }

    /* ====================================================================
    Blog Page
    =====================================================================*/

    .blog {
        margin-top: 10%;
        margin-bottom: 5%;

        .blog-body {


            .blog-card {
                width: calc(100% / 2.2);

                .blog-card-img {
                    img {
                        height: 100%;
                    }
                }

                .blog-card-text {
                    span {
                        font-size: 2vw;
                    }

                    a {
                        font-size: 2.3vw;
                    }
                }
            }
        }
    }

    .blog-details {
        margin-top: 25%;

        .blog-details-img {
            padding: 0;

            span {
                margin-top: 5%;
                font-size: 3.5vw;
            }

        }

        .blog-details-text {
            padding: 0;

            h1 {
                font-size: 5vw;
            }

            p {
                font-size: 4vw;

                strong {
                    font-size: 4.3vw;
                }
            }
        }
    }

}

@media (min-width: 1025px) and (max-width: $xl) {

    .project {
        .project-caption {
            h6 {
                font-size: 3vw;
            }

            .button {
                width: 60%;

                button {
                    font-size: 2.2vw;

                }
            }
        }
    }

    .slider {
        .form {
            form {
                textarea {
                    height: 15vh;
                }
            }
        }
    }
}