@import 'variables.scss';

@mixin Flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

@mixin Headline {
    h1 {

        padding: 0;
        width: 60%;
        margin: 0 auto;
        font-size: 2.5vw;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 5px;
        font-weight: bolder;
        position: relative;

        &:before {
            width: 28px;
            height: 5px;
            display: block;
            content: "";
            position: absolute;
            bottom: 4px;
            left: 50%;
            margin-left: -14px;
            background: $greenGradient;
        }

        &:after {
            width: 100px;
            height: 2px;
            display: block;
            content: "";
            position: relative;
            margin-top: 2%;
            left: 50%;
            margin-left: -50px;
            background: $greenGradient;
        }
    }

    h3 {
        padding: 0;
        width: 60%;
        margin: 5% auto 3% auto;
        font-size: 2.5vw;
        text-align: center;
        text-transform: capitalize;
        padding-bottom: 5px;
        font-weight: bolder;
        position: relative;

        &:before {
            width: 28px;
            height: 6px;
            display: block;
            content: "";
            position: absolute;
            bottom: 3px;
            left: 50%;
            margin-left: -14px;
            background: $greenGradient;
        }

        &:after {
            width: 100px;
            height: 2px;
            display: block;
            content: "";
            position: relative;
            margin-top: 2%;
            left: 50%;
            margin-left: -50px;
            background: $greenGradient;
        }
    }
}